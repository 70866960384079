.App {
  text-align: center;
}

.App-logo {
  height: 38vmin;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.25s;
}

.App-logo:hover{
  height: 42vmin;
}

.App-logo:active {
  height: 33vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
/* Top */
.div1 { grid-area: 1 / 4 / 3 / 9; }
/* buttom */
.div2 { grid-area: 7 / 4 / 7 / 9; }
/* Logo window */
.div3 { grid-area: 2 / 1 / 7 / 12; } 
/* Full window, without navbar */
.div4 { grid-area: 1 / 1 / 8 / 12; }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*  */
.slide-enter,
.slide-exit {
  transition: transform 500ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}
/*  */
.slide2-enter,
.slide2-exit {
  transition: transform 500ms ease-out;
}

.slide2-enter {
  transform: translateY(100%);
}

.slide2-enter.slide2-enter-active {
  transform: translateY(0%);
}

.slide2-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
}

.slide2-exit-active {
  transform: translateY(-100%);
}

iframe {
  display: block;       /* iframes are inline by default */
  border: none;         /* Reset default border */
  height: 80vh;        /* Viewport-relative units */
  width: 100vw;
}